import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';

type Props = {
  defaultOpen?: boolean;
  title: string;
  titleClass?: string;
  children: ReactNode;
};

const CollapsibleSection = ({ defaultOpen, title, titleClass = '', children }: Props) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-orange-900 bg-orange-100 rounded-lg hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
            <span className={titleClass}>{title}</span>
            <ChevronUpIcon
              className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-orange-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2">{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CollapsibleSection;
