import { BreadcrumbJsonLd, NextSeo } from 'next-seo';
import Image from 'next/image';
import Link from 'next/link';

import { PlayIcon } from '@heroicons/react/outline';
import useCrispChat from '../client/hooks/useCrispChat';
import Beehiiv from '../client/components/Icons/Beehiiv';
import LandingPreview from '../client/landing/LandingPreview';

function Homepage() {
  useCrispChat();

  return (
    <div className="">
      <NextSeo
        title="👋 Stackblocks - Embed your content on any website"
        description="No-code widget to showcase your work"
        canonical="https://stackblocks.app"
        openGraph={{
          url: 'https://stackblocks.app',
          title: '👋 Stackblocks - Embed your content on any website',
          description: 'No-code widget to showcase your work',
          images: [
            {
              url: `https://stackblocks.app/stackblocks-opengraph.png`,
              width: 1200,
              height: 627,
              alt: 'Stackblocks screenshot',
            },
          ],
          site_name: 'Stackblocks Website',
        }}
        twitter={{
          handle: '@aravindballa',
          site: '@StackblocksApp',
          cardType: 'summary_large_image',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Stackblocks',
            item: `https://stackblocks.app`,
          },
        ]}
      />

      <main>
        <section className="relative overflow-hidden pt-12 md:pt-32 px-8 sm:px-12 gap-x-6">
          <div className="mx-auto flex flex-col items-center">
            <div className="inline-flex space-x-2 mb-6">
              <span className="rounded bg-orange-100 px-2.5 py-1 text-xs font-semibold text-orange-500 tracking-wide uppercase">
                What's new
              </span>
              <span className="inline-flex items-center text-sm font-medium text-orange-500 space-x-1">
                <span>Support for Podcasts ⚡</span>
              </span>
            </div>
            <h1 className="text-4xl sm:text-6xl font-bold text-center">
              <span className="bg-clip-text text-transparent bg-gradient-to-br from-yellow-400 to-orange-500">
                Embed your content
              </span>{' '}
              <br /> on any website
            </h1>
            <p className="text-gray-500 mt-8 text-lg drop-shadow">
              No-code widget, to showcase your work
            </p>
            <div className="flex items-start my-16 gap-4">
              <div className="flex flex-col gap-4">
                <Link href="/get-started">
                  <a className="block rounded-md border border-transparent shadow-lg px-6 py-2 bg-orange-600 font-bold text-orange-50 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                    Create your Embed
                  </a>
                </Link>
                <div className="text-gray-500 text-xs text-center">7-day complete free trial</div>
              </div>
            </div>
          </div>

          <div className="min-w-screen relative -mt-36 -mx-28 pointer-events-none select-none flex items-center -z-10">
            <img
              className="w-full min-w-[900px]"
              src="/landing/embeds.png"
              width={1624}
              height={788}
              alt="screenshot of all the embeds"
            />
          </div>

          <div className="absolute bottom-0 w-full aspect-video -z-20 opacity-60">
            <div className="absolute sm:block top-4 left-16 h-1/2 w-1/2 rounded-full bg-orange-500 mix-blend-multiply filter blur-2xl opacity-80 animate-blob"></div>
            <div className="absolute sm:block top-16 left-40 h-2/3 w-2/3 rounded-full bg-yellow-500 mix-blend-multiply filter blur-2xl opacity-80 animate-blob animate-delay-2000"></div>
            <div className="absolute sm:block top-8 left-72 h-1/3 w-1/3 rounded-full bg-red-400 mix-blend-multiply filter blur-2xl opacity-80 animate-blob animate-delay-4000"></div>
          </div>

          <div className="static mt-4 md:absolute z-10 bottom-[10%] left-2/3 flex justify-center">
            <Link href="/showcase">
              <a className=" block rounded-md border border-orange-200 shadow-sm  px-6 py-2 bg-orange-50 font-bold text-orange-600 hover:text-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                Checkout the Showcase ↗
              </a>
            </Link>
          </div>
        </section>
        <section className="pb-8 px-8 sm:px-12 bg-gray-100">
          <div className="flex w-full max-w-5xl mx-auto mt-12 pt-16 md:mt-32">
            <div className="flex flex-col sm:flex-row gap-8 col-span-3">
              <div className="flex flex-col items-center">
                <div className="text-gray-900 text-sm font-bold">Sources</div>
                <div className="text-gray-500 text-sm mt-2 mb-12">
                  Create embeds from one of these
                </div>
                <div className="flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="object-contain"
                      src="/substack.png"
                      width={170}
                      height={30}
                      alt="substack logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="object-contain"
                      src="/revue.png"
                      width={120}
                      height={49}
                      alt="revue logo"
                    />
                  </div>
                  <div className="flex">
                    <Beehiiv className="object-contain" />
                  </div>
                </div>
                <div className="mt-8 flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90  object-contain"
                      src="/landing/podcasts.png"
                      width={171}
                      height={50}
                      alt="podcasts logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90 object-contain"
                      src="/landing/ghost.png"
                      width={159}
                      height={58}
                      alt="ghost logo"
                    />
                  </div>
                </div>
                <div className="mt-8 flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90 object-contain"
                      src="/landing/rss.png"
                      width={119}
                      height={50}
                      alt="ghost logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full max-w-5xl mx-auto pb-16 mt-24 md:mt-0">
            <div className="flex flex-col sm:flex-row gap-8 col-span-3">
              <div className="flex flex-col items-center">
                <div className="text-gray-900 text-sm font-bold">Where the embed can go</div>
                <div className="text-gray-500 text-sm mt-2 mb-12">Literally on any website</div>
                <div className="flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90 object-contain"
                      src="/landing/ghost.png"
                      width={159}
                      height={58}
                      alt="ghost logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90 object-contain"
                      src="/landing/carrd.png"
                      width={122}
                      height={40}
                      alt="carrd logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90 object-contain"
                      src="/landing/webflow.png"
                      width={143}
                      height={36}
                      alt="webflow logo"
                    />
                  </div>
                </div>
                <div className="mt-8 flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/html.png"
                      width={161}
                      height={50}
                      alt="html logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/feather.png"
                      width={130}
                      height={40}
                      alt="feather logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/framer.png"
                      width={146}
                      height={40}
                      alt="framer logo"
                    />
                  </div>
                </div>
                <div className="mt-8 flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/super.png"
                      width={143}
                      height={40}
                      alt="super logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/wix.png"
                      width={72}
                      height={28}
                      alt="wix logo"
                    />
                  </div>
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/notion.png"
                      width={50}
                      height={50}
                      alt="notion logo"
                    />
                  </div>
                </div>
                <div className="mt-8 flex items-center h-full space-x-4">
                  <div className="flex">
                    <Image
                      className="scale-90"
                      src="/landing/wordpress.png"
                      width={193}
                      height={40}
                      alt="wordpress logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-12 md:mt-32 flex flex-col mx-auto items-center col-span-2 w-full max-w-5xl group">
            <div className="text-gray-600 mb-8">See it for yourself 👇</div>
            <div className="relative pb-[56.25%] pt-6 w-full">
              <div className="absolute inset-0 bg-gradient-to-br from-orange-500 via-red-400 to-yellow-500 blur-xl opacity-80 group-hover:opacity-100"></div>
              <iframe
                className="absolute top-0 bottom-0 rounded-md w-full h-full"
                width="1022"
                height="575"
                src="https://www.youtube.com/embed/qpVnMtJbqbo"
                title="Embed Substack archive on your personal website"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div> */}
        </section>

        <section className="py-12 md:py-32 px-8 sm:px-12">
          <div className="mx-auto text-center">
            <h2 className="text-3xl font-bold">How it works ⚡️</h2>
            <div className="relative mt-12 lg:mt-20">
              <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                <img className="w-full" src="/curved-dotted-line.svg" alt="" />
              </div>

              <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-lg font-semibold text-gray-700"> 1 </span>
                  </div>
                  <h3 className="mt-6 text-lg font-semibold leading-tight text-black md:mt-10">
                    Enter your Source&nbsp;URL
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Choose the source and give us the URL.
                  </p>
                </div>

                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-lg font-semibold text-gray-700"> 2 </span>
                  </div>
                  <h3 className="mt-6 text-lg font-semibold leading-tight text-black md:mt-10">
                    Customize
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Select styles, colours and fonts for your Embed
                  </p>
                </div>

                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-lg font-semibold text-gray-700"> 3 </span>
                  </div>
                  <h3 className="mt-6 text-lg font-semibold leading-tight text-black md:mt-10">
                    Copy &amp; Paste
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Take the snippet and paste it in your Website 🎉
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* TODO gif that walks through the setup */}
        </section>

        <h2 id="features" className="sr-only">
          Features
        </h2>
        <section className="bg-gray-100 py-12 md:py-32 px-8 sm:px-12">
          <div className="md:grid grid-cols-3 gap-12">
            <div className="max-w-md flex mb-12 md:mb-0">
              <svg
                className="w-6 h-6 text-orange-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
              <div className="flex-1">
                <h3 className="font-bold text-gray-900">Customizable</h3>
                <p className="mt-2 text-gray-600">
                  Choose the number of issues to show from your latest or top newsletter issues.
                </p>
              </div>
            </div>
            <div className="max-w-md flex mb-12 md:mb-0">
              <svg
                className="w-7 h-7 text-orange-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <div className="flex-1">
                <h3 className="font-bold text-gray-900">As easy as copy-paste</h3>
                <p className="mt-2 text-gray-600">Just paste 2 lines of HTML to get it working.</p>
                <p className="mt-2 text-gray-600">
                  Give us your newsletter URL and set few preferences, we give you HTML that just
                  works.
                </p>
              </div>
            </div>
            <div className="max-w-md flex mb-12 md:mb-0">
              <svg
                className="w-7 h-7 text-orange-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                />
              </svg>
              <div className="flex-1">
                <h3 className="font-bold text-gray-900">Always upto-date</h3>
                <p className="mt-2 text-gray-600">
                  Refreshes the content as soon as you publish a new issue. Never stale.
                </p>
              </div>
            </div>
            <div className="max-w-md flex mb-12 md:mb-0">
              <svg
                className="w-7 h-7 text-orange-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
              <div className="flex-1">
                <h3 className="font-bold text-gray-900">Works with website builders</h3>
                <p className="mt-2 text-gray-600">
                  Supports a wide range of website builders like{' '}
                  <a
                    className="underline"
                    href="https://super.so"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    super.so
                  </a>
                  ,{' '}
                  <a
                    className="underline"
                    href="https://carrd.co"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Carrd
                  </a>
                  ,{' '}
                  <a
                    className="underline"
                    href="https://mdx.one"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    MDX.one
                  </a>
                  ,{' '}
                  <a
                    className="underline"
                    href="https://potion.so"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Potion
                  </a>
                  , etc. Embed the content and you will be off to races.
                </p>
              </div>
            </div>
            <div className="max-w-md flex mb-12 md:mb-0">
              <svg
                className="w-6 h-6 text-orange-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                />
              </svg>
              <div className="flex-1">
                <h3 className="font-bold text-gray-900">Theme-able</h3>
                <p className="mt-2 text-gray-600">
                  Add custom CSS to make it look like a perfect fit for your website or blog.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 md:py-32 px-8 sm:px-12 relative">
          {/* <div className="absolute inset-0 bg-gradient-to-br from-white via-gray-200 to-white -z-10 grid grid-cols-12">
            {Array.from(10)}
          </div> */}
          <h2 id="see-it-in-action" className="text-3xl font-bold text-center">
            See it in action 💃
          </h2>
          <div className="mt-1 text-center text-gray-700">
            Look at the knobs you can turn to make <br />
            the embed feel native on your site.
          </div>
          <div className="flex flex-col-reverse md:flex-row justify-between items-start gap-8 mt-24 w-full max-w-5xl mx-auto">
            <LandingPreview />
          </div>
        </section>

        <section className="bg-gray-100 py-12 md:py-32 px-8 sm:px-12">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 id="testimonials" className="text-3xl font-bold">
                Good things people have said 🙌
              </h2>
            </div>

            <div className="grid max-w-lg grid-cols-1 gap-10 mx-auto mt-8 md:max-w-4xl md:mt-16 md:grid-cols-2 md:gap-x-16">
              <div>
                <blockquote className="py-6 bg-gradient-to-br from-gray-300 via-gray-100 to-gray-200 hover:shadow-md rounded-2xl px-7">
                  <p className="font-normal leading-relaxed text-gray-900 font-pj">
                    “Stackblocks provided an extremely simple and hassle-free method to integrate my
                    substack newsletter on my website. It lets you customize how you want it to
                    appear on your website and is as simple as copy and pasting. It has definitely
                    made my life much easier and I would recommend it to others as well.”
                  </p>
                </blockquote>
                <div className="flex items-center mt-5">
                  <img
                    className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                    src="/prabal.jpeg"
                    alt="Prabal Gupta avatar"
                  />
                  <div className="ml-2">
                    <p className="text-base font-bold text-gray-900 font-pj">Prabal Gupta</p>
                    <p className="mt-0.5 font-pj text-sm text-gray-600">
                      Podcast Host, The Capital Analyst &middot; MBA, IIM Calcutta '22
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <blockquote className="py-6 bg-gradient-to-br from-gray-300 via-gray-100 to-gray-200 hover:shadow-md rounded-2xl px-7">
                  <p className="font-normal leading-relaxed text-gray-900 font-pj">
                    “You'd think embedding a feed of your substack content into a website would be
                    simple, but it wasn't for me–that is, until I discovered Stackblocks.
                    Stackblocks is excellent, simple to use, and delivers exactly what you want it
                    to.”
                  </p>
                </blockquote>
                <div className="flex items-center mt-5">
                  <img
                    className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                    src="/cosmo.jpeg"
                    alt="Cosmo avatar"
                  />
                  <div className="ml-2">
                    <p className="text-base font-bold text-gray-900 font-pj">Cosmo Wallace</p>
                    <p className="mt-0.5 font-pj text-sm text-gray-600">Director &middot; Writer</p>
                  </div>
                </div>
              </div>
              <div>
                <blockquote className="py-6 bg-gradient-to-br from-gray-300 via-gray-100 to-gray-200 hover:shadow-md rounded-2xl px-7">
                  <p className="font-normal leading-relaxed text-gray-900 font-pj">
                    “Stackblocks helped me embed my content from Substack and easily apply styling
                    without having to mess around with anything custom. Their support team also
                    responded very quickly to my questions and provided multiple solutions that in
                    the end helped me get my website up and running.”
                  </p>
                </blockquote>
                <div className="flex items-center mt-5">
                  <img
                    className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                    src="/kyle.jpeg"
                    alt=""
                  />
                  <div className="ml-2">
                    <p className="text-base font-bold text-gray-900 font-pj">Kyle Nelson</p>
                    <p className="mt-0.5 font-pj text-sm text-gray-600">Tech Writer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 md:py-32 md:grid grid-cols-2 px-8 sm:px-12">
          <div className="mx-8 mb-16 md:mb-0 rounded-2xl shadow-lg relative">
            <Image
              alt="Preview of a site using Stackblocks"
              src="/preview.png"
              width={720}
              height={944}
              layout="responsive"
              priority
            />
            <img
              alt=""
              className="preview-handwriting absolute md:block hidden w-auto h-1/3"
              src="/from-sb.png"
              width={523}
              height={430}
            />
            <div className="absolute mt-4 text-xs text-center w-full text-gray-300">
              <p>
                Preview content from{' '}
                <a
                  className="underline"
                  href="https://personal-site.super.so/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://personal-site.super.so/
                </a>
              </p>
              <p>
                Substack content from{' '}
                <a className="underline" href="https://on.substack.com/archive">
                  https://on.substack.com/archive
                </a>
              </p>
            </div>
          </div>
          <div className="mt-32 md:mt-0">
            <h2 className="text-3xl font-bold">Don't just ask emails</h2>
            <p className="text-gray-500 mt-8">
              Let people see preview of your content so they fall in love, instantly. 😍
            </p>
          </div>
        </section>

        <section className="bg-gray-100 py-12 md:py-32 px-8 sm:px-12">
          <h2 id="be-first-to-know" className="mb-12 text-3xl font-bold text-center">
            Start embedding your content ⚡
          </h2>
          <p className="mt-4 text-gray-700 text-center max-w-xl mx-auto">
            We are actively developing support for other sources. You can follow{' '}
            <a
              className="underline"
              href="https://twitter.com/aravindballa"
              target="_blank"
              rel="noreferrer noopener"
            >
              Aravind
            </a>{' '}
            on Twitter to get updates.
          </p>
          <div className="mt-4 flex items-center justify-center gap-2">
            <PlayIcon className="w-5 h-5" />
            <span>
              Watch a{' '}
              <a
                className="underline"
                href="https://www.youtube.com/watch?v=qpVnMtJbqbo"
                target="_blank"
                rel="noreferrer noopener"
              >
                demo video
              </a>{' '}
              (~2min).
            </span>
          </div>
          <div className="mt-8 text-center max-w-xl mx-auto">
            <Link href="/get-started">
              <a className="inline-block rounded-md border border-transparent shadow-sm  px-6 py-2 bg-orange-600 font-bold text-orange-50 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                Get started
              </a>
            </Link>
          </div>
        </section>
        <div className="bg-gray-100">
          <div className="border-t max-w-sm mx-auto"></div>
        </div>
      </main>
    </div>
  );
}

export default Homepage;
