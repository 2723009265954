import { useReducer } from 'react';
import { ProjectSettings, SubstackIssue } from '../types';

type State = {
  projectSettings: ProjectSettings | null;
  issues: SubstackIssue[];
  isLoadingIssues: boolean;
};

type ActionSetIssues = { type: 'SET_ISSUES'; payload: { issues: SubstackIssue[] } };
type ActionSetProjectSettings = {
  type: 'SET_PROJECT_SETTINGS';
  payload: { projectSettings: ProjectSettings };
};
type ActionUpdateProjectSettings = {
  type: 'UPDATE_PROJECT_SETTINGS';
  payload: { projectSettings: Partial<ProjectSettings> };
};
type ActionSetLoadingIssues = { type: 'SET_LOADING_ISSUES'; payload: { isLoading: boolean } };

type Action =
  | ActionSetIssues
  | ActionSetProjectSettings
  | ActionUpdateProjectSettings
  | ActionSetLoadingIssues;

export const initialState: State = {
  projectSettings: null,
  issues: [],
  isLoadingIssues: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ISSUES':
      return {
        ...state,
        issues: action.payload.issues,
      };
    case 'SET_PROJECT_SETTINGS':
      return {
        ...state,
        projectSettings: action.payload.projectSettings,
      };
    case 'UPDATE_PROJECT_SETTINGS':
      return {
        ...state,
        projectSettings: state.projectSettings
          ? {
              ...state.projectSettings,
              ...action.payload.projectSettings,
            }
          : state.projectSettings,
      };
    case 'SET_LOADING_ISSUES':
      return {
        ...state,
        isLoadingIssues: action.payload.isLoading,
      };
    default:
      return state;
  }
};

const useAppReducer = () => {
  const [{ projectSettings, issues, isLoadingIssues }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const updateProjectSettings = (changes: Partial<Omit<ProjectSettings, 'id'>>) => {
    dispatch({
      type: 'UPDATE_PROJECT_SETTINGS',
      payload: { projectSettings: { ...projectSettings, ...changes } },
    });
  };

  const setProjectSettings = (projectSettings: ProjectSettings) => {
    dispatch({
      type: 'SET_PROJECT_SETTINGS',
      payload: { projectSettings },
    });
  };

  const setIssues = (issues: SubstackIssue[]) => {
    dispatch({
      type: 'SET_ISSUES',
      payload: { issues },
    });
  };

  const setLoadingIssues = (isLoading: boolean) => {
    dispatch({
      type: 'SET_LOADING_ISSUES',
      payload: { isLoading },
    });
  };

  return {
    projectSettings,
    issues,
    isLoadingIssues,
    updateProjectSettings,
    setProjectSettings,
    setIssues,
    setLoadingIssues,
  };
};

export default useAppReducer;
