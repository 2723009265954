import { ButtonHTMLAttributes } from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  kind?: 'primary' | 'secondary' | 'link';
  size?: 'default' | 'small' | 'large';
};

const kindClasses = {
  primary:
    'inline-flex items-center border border-transparent font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
  secondary: 'text-gray-700 bg-gray-200 hover:bg-gray-300',
  link: 'underline text-gray-700',
};

const sizeClasses = {
  default: 'text-sm py-2 px-4',
  small: 'text-xs py-1 px-4',
  large: 'py-2 px-4',
};

const Button = ({
  className = '',
  children,
  kind = 'secondary',
  size = 'default',
  disabled,
  ...rest
}: Props & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`rounded-md disabled:opacity-80 disabled:pointer-events-none focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-200 ${
        kindClasses[kind]
      } ${sizeClasses[size]} ${disabled ? 'opacity-40 cursor-not-allowed' : ''} ${className}`}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
