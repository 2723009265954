const Beehiiv = ({ className = '', props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 439.4 110.2"
      xmlSpace="preserve"
      className={`fill-black h-[28px] lg:h-[32px] ${className}`}
      {...props}
    >
      <path
        className="st0"
        d="M101.7 51.8H15.3c-2.2 0-4-1.8-4-4v-6.1c0-6.9 5.6-12.5 12.5-12.5h69.3c6.9 0 12.5 5.6 12.5 12.5v6.1c.1 2.2-1.7 4-3.9 4zM83.5 22.6h-50c-2.2 0-4.1-1.8-4.1-4.1C29.4 8.3 37.7 0 47.9 0h21.2c10.2 0 18.5 8.3 18.5 18.5 0 2.3-1.8 4.1-4.1 4.1zM105.7 58.4H11.3C5.1 58.4 0 63.5 0 69.7S5.1 81 11.3 81h33.5c1.2-6.4 6.8-11.3 13.6-11.3S70.9 74.6 72.1 81h33.5c6.2 0 11.3-5.1 11.3-11.3s-5-11.3-11.2-11.3zM101.6 87.6H72.3v22.6h22c6.2 0 11.3-5.1 11.3-11.3v-7.2c.1-2.2-1.7-4.1-4-4.1zM15.3 87.6c-2.2 0-4.1 1.8-4.1 4.1v7.2c0 6.2 5.1 11.3 11.3 11.3h22V87.6H15.3z"
      />
      <g>
        <path
          className="st1"
          d="M206.4 63c0 12.3-8.5 20.2-19.6 20.2-5.3 0-9.5-1.7-12.3-5.1v4.5h-10.9V28.5H175v19.1c2.9-3.2 6.9-4.8 11.8-4.8 11 0 19.6 7.9 19.6 20.2zm-11.6 0c0-6.8-4.3-10.9-10-10.9s-10 4.1-10 10.9 4.3 10.9 10 10.9 10-4.1 10-10.9zM243.6 70.3l6.1 6.6c-3.6 4.2-9.1 6.3-16.2 6.3-13.6 0-22.4-8.5-22.4-20.2s8.9-20.2 21-20.2c11.1 0 20.2 7.3 20.3 19.9l-29.1 5.6c1.7 3.9 5.3 5.8 10.4 5.8 4.2 0 7.2-1.2 9.9-3.8zm-21.4-8.5l19.1-3.7c-1.1-4-4.5-6.7-9.3-6.7-5.6 0-9.6 3.8-9.8 10.4zM289.6 70.3l6.1 6.6c-3.6 4.2-9.1 6.3-16.2 6.3-13.6 0-22.4-8.5-22.4-20.2s8.9-20.2 21-20.2c11.1 0 20.2 7.3 20.3 19.9l-29 5.6c1.7 3.9 5.3 5.8 10.4 5.8 4.1 0 7.1-1.2 9.8-3.8zm-21.4-8.5l19.1-3.7c-1.1-4-4.5-6.7-9.3-6.7-5.6 0-9.5 3.8-9.8 10.4zM346.2 60.2v22.5h-11.4V61.9c0-6.3-2.9-9.3-8-9.3-5.5 0-9.4 3.4-9.4 10.6v19.4H306V28.5h11.4v19c3.1-3.1 7.5-4.7 12.5-4.7 9.3 0 16.3 5.4 16.3 17.4zM355.1 31.5c0-3.6 2.9-6.4 7.1-6.4s7.1 2.6 7.1 6.1c0 3.8-2.9 6.6-7.1 6.6s-7.1-2.7-7.1-6.3zm1.4 11.9h11.4v39.3h-11.4V43.4zM377.1 31.5c0-3.6 2.9-6.4 7.1-6.4s7.1 2.6 7.1 6.1c0 3.8-2.9 6.6-7.1 6.6s-7.1-2.7-7.1-6.3zm1.4 11.9h11.4v39.3h-11.4V43.4zM439.4 43.4l-16.6 39.3H411l-16.5-39.3h11.8l10.9 26.7 11.2-26.7h11z"
        />
      </g>
    </svg>
  );
};

export default Beehiiv;
