import { RefreshIcon } from '@heroicons/react/outline';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import Button from '../components/Button';
import CollapsibleSection from '../components/CollapsibleSection';
import ColourPicker from '../components/ColourPicker';
import Preview from '../components/Preview';
import Switch from '../components/Switch';
import useAppReducer from '../hooks/useAppReducer';
import { defaultBasicStyleConfig as defaults } from '../lib';

const defaultIssues = [
  {
    url: 'https://letter.salman.io/p/bumps-are-the-road',
    imgUrl:
      'https://substackcdn.com/image/fetch/h_600,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F144e0a3c-893f-40b3-8887-c481babf399c_1456x1456.jpeg',
    title: ' 🦊 Bumps Are the Road',
    description: '#98 — Life is meant for living, not waiting.',
    dateString: '2022-12-13T00:24:28.248Z',
    author: 'Salman Ansari',
    privateIssue: false,
  },
  {
    url: 'https://letter.salman.io/p/favorite-books-of-2022',
    imgUrl:
      'https://substackcdn.com/image/fetch/h_600,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F90ad278d-278d-4938-823c-3cf89f537cf5_4032x3024.jpeg',
    title: ' 🦊 Favorite Books of 2022',
    description:
      '#97 — The best philosophy, creativity and fiction books I read this year, along with a few thoughts on my book reading habits.',
    dateString: '2022-12-06T02:26:25.406Z',
    author: 'Salman Ansari',
    privateIssue: false,
  },
  {
    url: 'https://letter.salman.io/p/inspirations',
    imgUrl:
      'https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/65073862-2f96-4536-9544-ccb1c38ce8eb_800x449.png',
    title: ' 🦊 Inspirations',
    description:
      "#96 — The greats I'm grateful for: Alan Watts, Liz Gilbert, Antoine de Saint-Exupéry, Joseph Campbell, Derek Sivers, Hayao Miyazaki and Peter de Seve.",
    dateString: '2022-11-29T03:09:04.471Z',
    author: 'Salman Ansari',
    privateIssue: false,
  },
  {
    url: 'https://letter.salman.io/p/sing-your-song',
    imgUrl:
      'https://substackcdn.com/image/fetch/h_600,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F8695c473-9ff0-4e0f-8a4b-3a4adf1424b2_2048x2732.png',
    title: '🦊 Sing Your Song',
    description:
      '#95 — A plea to publish: Share your words. In a world of information overload, we need your signal to survive the noise.',
    dateString: '2022-11-22T00:18:57.090Z',
    author: 'Salman Ansari',
    privateIssue: false,
  },
  {
    url: 'https://letter.salman.io/p/creative-leftovers',
    imgUrl:
      'https://substackcdn.com/image/fetch/h_600,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F44981d11-4156-460f-ac71-aac9a274d876_799x1000.jpeg',
    title: ' 🦊 Creative Leftovers',
    description:
      '#94 — Stop while the going is good. Leave a note on what comes next. Your future self will thank you.',
    dateString: '2022-11-15T05:21:36.921Z',
    author: 'Salman Ansari',
    privateIssue: false,
  },
];

const defaultBasicStyleConfig = {
  ...defaults,
  hideBranding: true,
};

const LandingPreview = () => {
  const { projectSettings, issues, updateProjectSettings, setProjectSettings, setIssues } =
    useAppReducer();

  useEffect(() => {
    setIssues(defaultIssues);
    setProjectSettings({
      newsletterType: 'substack',
      newsletterURL: 'https://letter.salman.io',
      id: 'landing',
      numberOfPostsToShow: 3,
      showAuthor: true,
      showDate: true,
      showImage: true,
      basicStyleConfig: defaultBasicStyleConfig,
      newsletterSettings: { sortOption: 'new' },
    });
  }, []);

  if (!projectSettings) return null;

  return (
    <>
      <Head>
        {projectSettings.basicStyleConfig?.fontName && (
          <link
            href={`https://fonts.googleapis.com/css2?family=${encodeURI(
              projectSettings.basicStyleConfig?.fontName
            )}&display=swap`}
            rel="stylesheet"
          />
        )}
      </Head>
      <div className="mx-auto flex flex-col gap-8 items-stretch">
        <CollapsibleSection title="Customize what to show" titleClass="font-bold" defaultOpen>
          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-between">
              <label htmlFor="posts-to-show" className="block text-sm font-medium text-gray-700">
                Number of posts
              </label>
              <input
                type="number"
                name="posts-to-show"
                min={1}
                max={5}
                id="posts-to-show"
                className="mt-2 appearance-none rounded-md relative block w-16 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                value={projectSettings.numberOfPostsToShow || 5}
                onChange={(e) => {
                  updateProjectSettings({
                    numberOfPostsToShow: parseInt(e.target.value),
                  });
                }}
              />
            </div>

            <Switch
              id="show-image"
              checked={projectSettings.showImage || false}
              onChange={(val) => {
                updateProjectSettings({ showImage: val });
              }}
              label="Show Images"
            />

            <Switch
              id="show-date"
              checked={projectSettings.showDate || false}
              onChange={(val) => {
                updateProjectSettings({ showDate: val });
              }}
              label="Show Published Date"
            />

            <Switch
              id="show-author"
              checked={projectSettings.showAuthor || false}
              onChange={(val) => {
                updateProjectSettings({ showAuthor: val });
              }}
              label="Show Author"
            />
          </div>
        </CollapsibleSection>

        <CollapsibleSection title="Advanced" titleClass="font-bold" defaultOpen>
          <div className="flex items-center justify-between gap-x-4">
            <div className="block text-sm font-medium text-gray-700">Background Colour</div>
            <ColourPicker
              color={projectSettings.basicStyleConfig?.bgColor || defaultBasicStyleConfig.bgColor}
              onChange={(color) => {
                updateProjectSettings({
                  basicStyleConfig: {
                    ...(projectSettings.basicStyleConfig || {}),
                    bgColor: color,
                  },
                });
              }}
            />
          </div>
          <div className="flex items-center justify-between gap-x-4 mt-4">
            <div className="block text-sm font-medium text-gray-700">Text Colour</div>
            <ColourPicker
              color={
                projectSettings.basicStyleConfig?.textColor || defaultBasicStyleConfig.textColor
              }
              onChange={(color) => {
                updateProjectSettings({
                  basicStyleConfig: {
                    ...(projectSettings.basicStyleConfig || {}),
                    textColor: color,
                  },
                });
              }}
            />
          </div>
          <div className="flex items-center justify-between gap-x-4 mt-4">
            <label htmlFor="font" className="block text-sm font-medium text-gray-700">
              Font
            </label>
            <div className="flex flex-col gap-1 relative">
              <input
                type="text"
                name="font"
                id="font"
                className="appearance-none text-right rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                value={projectSettings.basicStyleConfig?.fontName || ''}
                onChange={(e) => {
                  updateProjectSettings({
                    basicStyleConfig: {
                      ...(projectSettings.basicStyleConfig || {}),
                      fontName: e.target.value,
                    },
                  });
                }}
              />
              <p className="text-xs text-gray-400 text-right absolute -bottom-3 right-0">
                Pick one from{' '}
                <a
                  className="underline"
                  href="https://fonts.google.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Google Fonts
                </a>
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center mt-8">
            <Button
              kind="link"
              size="small"
              className="flex items-center gap-1"
              onClick={() => {
                updateProjectSettings({
                  basicStyleConfig: {
                    ...(projectSettings.basicStyleConfig || {}),
                    ...defaultBasicStyleConfig,
                  },
                });
              }}
            >
              <RefreshIcon className="w-3 h-3" />
              Reset defaults
            </Button>
          </div>
        </CollapsibleSection>

        <div className="text-xs text-center text-gray-700">
          You can even customize the CSS in the app!
        </div>
      </div>
      <div className="border rounded-md shadow-xl max-w-lg w-full transform -rotate-2">
        <Preview className="rounded-md" issues={issues} projectSettings={projectSettings} isFrame />
      </div>
    </>
  );
};

export default LandingPreview;
