import { useEffect } from 'react';

const useCrispChat = () => {
  useEffect(() => {
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = 'a4107555-0daf-49ba-9cfc-2851f6bcef71';
    (() => {
      const d = document;
      if (d.querySelector('#crisp-script')) return;
      const s = d.createElement('script');
      s.id = 'crisp-script';
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('body')[0].appendChild(s);
    })();
  }, []);
};

export default useCrispChat;
